import "./NFTModal.scss";
import space from "../../../assets/images/space.png";
import AnimatedImg from "../../../assets/images/animation.gif";
import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Modal, ModalFooter, ModalBody, ModalHeader } from "reactstrap";

export default function NFTModal(args) {
  const [modal, setModal] = useState(false);

  const closeHandler = () => {
    args.callback(false);
    setModal(false);
  };

  useEffect(() => {
    if (args?.show) {
      setModal(true);
    }
  }, [args.show]);
  return (
    <Modal className="default-modal-style" isOpen={modal} toggle={closeHandler}>
      <ModalHeader toggle={closeHandler}>
        <img src={space} alt="" />
        <img className="animationImg" src={AnimatedImg} alt="" />
      </ModalHeader>
      <ModalBody className="text-center">
        <h2>Congratulations!</h2>
        <p>Your NFT has been minted.</p>
      </ModalBody>
      <ModalFooter>
        <Button className="btn btn-primary sm-btn-style" onClick={closeHandler}>
          <span>Continue</span>
        </Button>
      </ModalFooter>
    </Modal>
  );
}

import { ethers } from "ethers";
import React, { useEffect } from "react";
import * as contract_abi from "../assets/json/erc20ABI.json";
import { useSelector } from "react-redux";
import sxtService from "./service";

export default function ERC20Contract() {
  const connection = useSelector((state) => state.connection);
  const contract = useSelector((state) => state.contract);

  useEffect(() => {
    setERC20Contract();
    //eslint-disable-next-line
  }, [connection.disconnected, contract]);

  async function setERC20Contract() {
    window.erc20contract = null;
    if (window.ethereum && window.contract && !connection.disconnected) {
      const erc20Address = await sxtService.getERC20ContractAddress();
      if (erc20Address) {
        const web3Provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = web3Provider.getSigner();
        const contract = new ethers.Contract(
          erc20Address,
          contract_abi.default.abi,
          signer
        );
        window.erc20contract = contract;
      } else {
        // return setTimeout(() => {
        //   return window.showSnackbar(
        //     "sxt token not found, Please set sxt token in contract.",
        //     "info"
        //   );
        // }, 2000);
      }
    }
  }
  return <></>;
}

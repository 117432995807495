import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col } from "reactstrap";
import SoldNFTSlider from "../../Common/SoldNFTSlider";
import NFTModal from "../../Common/Modal/NFTModal";
import CardSliderNew from "../../Common/AvailableNFTSlider";

export default function NFT() {
  const [modal, setModal] = useState(false);

  return (
    <Container>
      <Row className="d-flex align-items-center main-NFT-style">
        <Col md="6" sm="12" xs="12">
          <div className="dekstop-hide">
            <h2 className="title-lg">
              NFTs from Space and Time <br />
              <span> For Free </span>
            </h2>
            <p>
              Explore the exclusive SxT Community NFT collection. Each
              collection contains 420 ultra rare collectibles, has unique
              properties and is orbiting the Ethereum Blockchain.
            </p>
          </div>
          <div>
            <CardSliderNew />
          </div>
        </Col>
        <Col md="6" sm="12" xs="12">
          <div className="mobile-hide">
            <h2 className="title-lg">
              NFTs from Space and Time <br />
              <span> For Free </span>
            </h2>
            <p>
              Explore the exclusive SxT Community NFT collection. Each
              collection contains 420 ultra rare collectibles, has unique
              properties and is orbiting the Ethereum Blockchain. Connect your
              MetaMask wallet and begin collecting leading-edge SxT-inspired art
              by minting these on-chain using your wallet (and paying the gas).
              These NFTs are provided by SxT at no cost.
            </p>
          </div>
          <p className="dekstop-hide">
            Connect your MetaMask wallet and begin collecting leading-edge
            SxT-inspired art by minting these on-chain using your wallet (and
            paying the gas). These NFTs are provided by SxT at no cost.
          </p>
          <p className="text-bigger">
            Space and Time is the Verifiable Compute Layer for AI x Blockchain, delivering the first sub-second ZK proofs onchain.
          </p>
        </Col>
      </Row>

      {/* SOLD NFTS */}
      <SoldNFTSlider />
      {/* SOLD NFTS CLOSED */}

      <NFTModal show={modal} callback={setModal} />
    </Container>
  );
}

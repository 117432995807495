import { Snackbar, Alert } from "@mui/material";
import React, { useState } from "react";
import { Container } from "reactstrap";

export default function CustomSnackbar() {
  const [hasError, setHasError] = useState({
    show: false,
    message: "",
    variant: "error",
    time: 4000,
  });
  window.showSnackbar = (message, variant = "error", time = 4000) => {
    setHasError({
      ...hasError,
      message: message,
      variant: variant,
      show: true,
      time: time,
    });
  };

  function handleClose() {
    setHasError({ ...hasError, show: false });
  }
  return (
    <Container>
      <Snackbar
        open={hasError.show}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        autoHideDuration={hasError.time}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={hasError.variant}
          sx={{ width: "100%" }}
        >
          {hasError.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}

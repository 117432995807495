import React from "react";
import sold from "../../../assets/images/sold.svg";
export default function NFTitems({ title, quantity }) {
  return (
    <div className="default-box-style">
      <div className="default-bg-style">
        <div className="inner-default-box lg-box">
          <div className="image-content-wrap col-gap-15">
            <img src={sold} alt="" />
            <div>
              <span>{title}</span>
              <h5>{quantity}</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

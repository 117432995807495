import { configureStore } from "@reduxjs/toolkit";
import connectionReducer from "./states/connectionReducer";
import contractReducer from "./states/contractReducer";
import nftsReducer from "./states/nftsReducer";
import nftsReducerNew from "./states/nftsReducerNew";

const store = configureStore({
  reducer: {
    connection: connectionReducer,
    contract: contractReducer,
    nfts : nftsReducer,
    nftsList : nftsReducerNew
  },
});

export default store;

import React from "react";
import "./PageNotFound.scss";

import { Button, Container } from "reactstrap";
import { useNavigate } from "react-router-dom";

export default function PageNotFound() {
  const navigate = useNavigate();
  return (
    <Container>
      <div className="page-not-found">
        <h1 className="FOF-text">404</h1>
        <h2>This page is lost in Space</h2>
        <p>
          404 error means this link or URL is no active or available. Please
          double check the spelling in the link you are trying to navigate to.
        </p>
        <Button className="btn btn-primary" onClick={() => navigate("/")}>
          <span>Back to homepage</span>
        </Button>
      </div>
    </Container>
  );
}

import React from "react";
import spaceX3 from "../../../assets/images/creative-mars.jpg";
import { Card, CardBody, CardTitle} from "reactstrap";

export default function ComingSoon() {
  return (
    <div>
      <Card className="ComingSoonCard">
        <div className="cardImg-wrapper" id="cardImg-wrapper-blur">
          <img src={spaceX3} alt="" />
        </div>
        <CardBody className="d-flex align-items-center">
          <CardTitle tag="h5">New NFTs Coming Soon</CardTitle> 
        </CardBody>
      </Card>
    </div>
  );
}

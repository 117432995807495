import React, { useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import { CircularProgress } from "@mui/material";

export default function ProcessingModal(props) {
  const [modalState, setModalState] = useState({
    show: false,
    message: "",
  });

  function handleToggle() {
    setModalState({ ...modalState, show: !modalState.show });
  }

  window.showProgress = (message) => {
    setModalState({
      ...modalState,
      message: message,
      show: true,
    });
  };
  window.hideProgress = () => {
    setModalState({
      ...modalState,
      message: "",
      show: false,
    });
    const elem = document.querySelector('body');
    elem.style.removeProperty("overflow")
    elem.style.removeProperty("padding-right")
  };
  return (
    <div>
      <Modal
        isOpen={modalState.show}
        toggle={handleToggle}
        className={props.className || ""}
        backdrop={"static"}
        contentClassName="modal-content-custom"
      >
        <ModalBody
          style={{ color: "#000", backgroundColor: "transparent" }}
          className="d-flex justify-content-center align-items-center"
        >
          <CircularProgress color="secondary" size={30} className="me-2" />
          {modalState.message || ""}
        </ModalBody>
      </Modal>
    </div>
  );
}

import { Routes, Route, HashRouter } from "react-router-dom";
import Layout from "./Layout/Layout";
import NFT from "./components/Pages/NFT/nft";
import Contract from "./helpers/contract";
import CustomSnackbar from "./components/Common/Snackbar/Snackbar";
import ERC20Contract from "./helpers/erc20Contract";
import ProcessingModal from "./components/Common/ProcessingModal/ProcessingModal";
import NFTDetailNew from "./components/Pages/NFTDetail";
import SuccessModal from "./components/Common/Modal/NFTModalGlobal";
import PageNotFound from "./components/Common/PageNotFound";
import OLDNFTDetail from "./components/Pages/NFTDetail/oldNFTDetails";
import ENVMain from './environment'

function App() {
  
  const UseLayout = ({ Component, subheader = true, ...rest }) => {
    return (
      <Layout subheader={subheader}>
        <Component {...rest} />
      </Layout>
    );
  };

  return (
    <div className="App">
      <CustomSnackbar />
      <SuccessModal />
      <ProcessingModal />
      <Contract />
      <ERC20Contract />
      <>
        <HashRouter>
          <Routes>
            <Route path="/" element={<UseLayout Component={NFT} />} />
            <Route
              path="/nft-detail/:id"
              element={<UseLayout Component={NFTDetailNew} />}
            />
            <Route
              path="/hyperloop/details"
              element={<UseLayout Component={OLDNFTDetail} contractAddress={ENVMain.oldContractAddress}/>}
            />
            <Route
              path="/ai-speculation/details"
              element={<UseLayout Component={OLDNFTDetail} contractAddress={ENVMain.old2ContractAddress}/>}
            />
            <Route
              path="*"
              element={<UseLayout Component={PageNotFound} subheader={false} />}
            />
          </Routes>
        </HashRouter>
      </>
    </div>
  );
}

export default App;

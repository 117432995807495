import React from "react";
import { Button } from "reactstrap";
export default function MintNow({
  text,
  clickHandler,
  inActiveClickHandler = () => {},
  active = true,
}) {
  return (
    <>
      {active ? (
        <Button
          className="btn btn-primary "
          onClick={() => {
            if (active && clickHandler) {
              clickHandler();
            } else {
              inActiveClickHandler();
            }
          }}
        >
          <span>{text || "Mint Now"}</span>
        </Button>
      ) : (
        <Button className="btn btn-disabled">{text || "Sold Out"}</Button>
      )}
    </>
  );
}

import "bootstrap/dist/css/bootstrap.min.css";
import "./nft-detail.scss";
import { Container, Row, Col } from "reactstrap";
import NFTitems from "../../Common/NFTitems";
// import MintNow from "../../Common/MintNowButton";
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";
import sxtService from "../../../helpers/service";
import { reloadNFTs } from "../../../redux/states/nftsReducerNew";
import ABI from "../../../assets/json/contractABI.json";
import ENVMain from "../../../environment";
import { ethers } from "ethers";

export default function OLDNFTDetail(props) {
  const { contractAddress } =  props
  const connection = useSelector((state) => state.connection);
  // const contract = useSelector((state) => state.contract);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [tokenDetails, setTokenDetails] = useState(null);
  const [isPrevMinted, setisPrevMinted] = useState(null);
  const [isTotalSold, setisTotalSold] = useState(null);
  const [convertedErc20Price, setconvertedErc20Price] = useState(0);
  const address = contractAddress;
  const abi = ABI.abi;
  const provider = new ethers.providers.JsonRpcProvider(
    ENVMain.otherProviderURL
  );
  let contract = new ethers.Contract(address, abi, provider);

  const isPreviouslyMinted = useCallback(
    async (index) => {
      if (contract && !connection.disconnected) {
        const address = connection.accountAddress;
        let result = false;
        if (index) {
          result = await contract.isPreviouslyMintedFrom(index, address);
        }
        setisPrevMinted(result || false);
        setLoading(false);
      } else {
        setLoading(false);
        setisPrevMinted(false);
      }
    },
    [connection.accountAddress, connection.disconnected]
  );

  const retrieveNFTs = useCallback(() => {
    setTimeout(async () => {
      /** here we are updating contract */
      /** if state have contractAddress then retrieve NFTs from mentioned contractAddress */
      /** we are using same detail page for all old contracts */
      /** we are passing contract address from NFTCard component.. */
      /** if here we would get contractAddress then we will use provided contract address otherwise */
      /** we will use first old contract address which is containing HYPERLOOP NFT */


      if (contract) {
        setLoading(true);
        setisTotalSold(false);
        const nftIndex = 1;
        const data = await sxtService.getNFTDetailsByContract(
          nftIndex,
          contract
        );
        if (!data) {
          window.showSnackbar("NFT doesn't exists.", "info");
          return navigate("/");
        }
        if (data?.maxTokenSupply === data?.currentTokenSupply) {
          setisTotalSold(true);
        }
        isPreviouslyMinted(data.id);
        setTokenDetails(data);
       
      }
    }, 0);
  }, [contract.connected, isPreviouslyMinted, navigate]);

  let init = useCallback(() => {
    setLoading(true);
    retrieveNFTs()
  }, [
    connection.disconnected,
    contract.connected,
  ]);

  const isMintProcessPending = useCallback(async () => {
    if (localStorage.getItem("thash")) {
      window.showProgress("Mint processing, Please don't refresh the page.");
      let progressInterval = setInterval(() => {
        sxtService
          .getTransactionReceipt(localStorage.getItem("thash"))
          .then((res) => {
            if (res) {
              localStorage.removeItem("thash");
              window.hideProgress();
              if (res.status === 1) {
                init();
                window.showSuccessModal();
                clearInterval(progressInterval);
                dispatch(reloadNFTs());
              } else {
                window.showSnackbar("Mint process failed.");
                clearInterval(progressInterval);
              }
            }
          });
      }, 4000);
    }
  }, [dispatch, init]);

  useMemo(async () => {
    if (tokenDetails) {
      const erc20P = await sxtService.convertToErc20(tokenDetails.erc20Price);
      setconvertedErc20Price(erc20P);
      return erc20P;
    }
  }, [tokenDetails]);

  useEffect(() => {
    window.scrollTo(0, 0);
    init();
    isMintProcessPending();
  }, [connection.disconnected, init, isMintProcessPending]);

  const onMintSuccess = () => {
    setLoading(true);
    setisPrevMinted(false);
    setisTotalSold(false);
    retrieveNFTs();
  };

  async function mintClickHandler(type) {
    let isReadyToMint = await sxtService.isEnvironmentReadyToMint();
    if (tokenDetails && isReadyToMint)
      sxtService.mintNFT(
        type,
        tokenDetails.id,
        connection.accountAddress,
        tokenDetails.ethPrice,
        tokenDetails.erc20Price,
        onMintSuccess
      );
  }

  const AlreadyAirdropped = () => {
    const alreadyHave = <span>You already own an instance of this NFT</span>;
      if (!tokenDetails) return <>...</>;
      if (isPrevMinted) return alreadyHave;
  }

  // const MintButtonsNew = () => {
  //   const alreadyHave = <span>You already own an instance of this NFT</span>;
  //   const allSold = <h5>All NFTs are sold.</h5>;
  //   if (!tokenDetails) return <>...</>;
  //   if (isPrevMinted) return alreadyHave;
  //   //const { ethPrice, erc20Price, hasPrice } = tokenDetails;
  //   // if (!hasPrice)
  //   //   return <MintNow clickHandler={() => mintClickHandler("free")} />;

  //   // if (ethPrice > 0 && erc20Price > 0) {
  //   //   return (
  //   //     <>
  //   //       <MintNow
  //   //         text={`${sxtService.convertToEth(ethPrice)} ETH`}
  //   //         clickHandler={() => mintClickHandler("eth")}
  //   //       />
  //   //       <MintNow
  //   //         text={`${convertedErc20Price || 0} ${tokenDetails.symbol}`}
  //   //         clickHandler={() => mintClickHandler("erc20")}
  //   //       />
  //   //     </>
  //   //   );
  //   // } else if (ethPrice > 0 && erc20Price.toString() === "0") {
  //   //   return (
  //   //     <MintNow
  //   //       text={`${sxtService.convertToEth(ethPrice)} ETH`}
  //   //       clickHandler={() => mintClickHandler("eth")}
  //   //     />
  //   //   );
  //   // } else if (ethPrice.toString() === "0" && erc20Price > 0) {
  //   //   return (
  //   //     <MintNow
  //   //       text={`${convertedErc20Price || 0} ${tokenDetails.symbol}`}
  //   //       clickHandler={() => mintClickHandler("erc20")}
  //   //     />
  //   //   );
  //   // }
  // };

  function Loader() {
    return (
      <>
        {loading && (
          <div className="d-flex w-100 justify-content-center">
            <CircularProgress color="secondary" size={30} />
          </div>
        )}
      </>
    );
  }

  function NFTDetails() {
    return (
      <>
        {!loading && tokenDetails && (
          <>
            <Col lg="6" md="12" sm="12" xs="12">
              <div
                className="img-wrapper"
                style={{
                  backgroundImage: `url(${tokenDetails?.imageURL || ""})`,
                }}
              ></div>
            </Col>
            <Col lg="6" md="12" sm="12" xs="12">
              <h2>{tokenDetails?.name || "SpaceNFT-X"}</h2>
              {!loading ?  <AlreadyAirdropped />: <CircularProgress size={10} />}
              <br />
              <div className="sold-total-item-btn">
                <NFTitems
                  title="Sold Items"
                  quantity={tokenDetails?.currentTokenSupply || 0}
                />
                <NFTitems
                  title="Total Items"
                  quantity={tokenDetails?.maxTokenSupply || 0}
                />
              </div>
              <div className="row">
                <div className="col mb-4  mt-4">
                  <h3>Description</h3>
                  <p>{tokenDetails?.description || "Not available"}</p>
                </div>
              </div>
            </Col>
          </>
        )}
      </>
    );
  }

  return (
    <Container>
      <Row className="detail-page-style">
        <Loader />
        <NFTDetails />
      </Row>
    </Container>
  );
}

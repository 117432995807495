import { createSlice } from "@reduxjs/toolkit";
export const nftListSlice = createSlice({
  name: "nftslist",
  initialState: {
    nftFetchRequired: false,
    lastfetched: 0,
    availableNFTs: [],
    availableNFTCount: 0,
    pageSize: 3,
    pageCount: 0,
    currentPage: 0,
    hasPrev: false,
    hasNext: false,
    soldNFTs: [],
    soldNFTCount: 0,
    fetchNFTs: false,
  },
  reducers: {
    setAvailableNFTs: (state, data) => {
      try {
        state.nftFetchRequired = false;
        state.fetchNFTs = false;
        state.lastfetched = Date.now();
        state.availableNFTCount = data.payload.count;
        state.pageCount = Math.ceil(data.payload.count / state.pageSize);
        state.currentPage = data.payload.currentPage;
        if (data.payload.currentPage === 1) {
          state.availableNFTs = data.payload.nfts;
        } else {
          if (state.availableNFTs.length < data.payload.count) {
            state.availableNFTs.push(...data.payload.nfts);
          }
        }
        if (data.payload.currentPage < state.pageCount) {
          state.hasNext = true;
        } else {
          state.hasNext = false;
        }
        if (state.availableNFTs > 1) {
          state.hasPrev = true;
        }
      } catch (err) {
        console.log(err);
      }
    },
    setSoldNFTs: (state, data) => {
      try {
        state.fetchNFTs = false;
        state.lastfetched = Date.now();
        state.soldNFTs = data.payload.nfts;
        state.soldNFTCount = data.payload.count;
      } catch (err) {
        console.log(err);
      }
    },
    reloadNFTs: (state) => {
      state.fetchNFTs = true;
      state.availableNFTs = [];
    },
  },
});

export const {
  setAvailableNFTs,
  setSoldNFTs,
  setNFTFetchRequired,
  reloadNFTs,
} = nftListSlice.actions;

export default nftListSlice.reducer;

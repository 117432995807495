import { CircularProgress } from "@mui/material";
import React from "react";

export default function Loading() {
  return (
    <div
      className="w-100 d-flex flex-column align-items-center"
      style={{ marginTop: "35px" }}
    >
      <CircularProgress color="secondary" />
      <h6 className="mt-3 text-secondary">Loading Sold NFTs</h6>
    </div>
  );
}

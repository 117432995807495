import { createSlice } from "@reduxjs/toolkit";
export const contractSlice = createSlice({
  name: "contract",
  initialState: {
    connected: false,
    name: "SpaceX",
    currentIndex: -1,
    contractAddress : "",
  },
  reducers: {
    mapContract: (state, data) => {
      state.connected = true;
      state.name = data.payload.name;
      state.currentIndex = data.payload.currentIndex;
      state.contractAddress = data.payload.contractAddress;
    },
  },
});

// Action creators are generated for each case reducer function
export const { mapContract } = contractSlice.actions;

export default contractSlice.reducer;

import React, { useCallback, useEffect, useRef, useState } from "react";
import NFTCard from "../NFTCard";
import { useDispatch, useSelector } from "react-redux";
import sxtService from "../../../helpers/service";
import { CircularProgress } from "@mui/material";
import ComingSoon from "../ComingSoonCard";
import { setAvailableNFTs } from "../../../redux/states/nftsReducerNew";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function CardSliderNew() {
  const connection = useSelector((state) => state.connection);
  const contract = useSelector((state) => state.contract);
  const nftsList = useSelector((state) => state.nftsList);
  const dispatch = useDispatch();
  const [nftFetched, setNftFetched] = useState(false);
  const [loading, setLoading] = useState(true);
  const [activeIndex, setactiveIndex] = useState(0);
  const [items, setItems] = useState([]);
  const sliderRef = useRef();

  useEffect(() => {
    setItems(nftsList.availableNFTs);
  }, [nftsList.availableNFTs]);

  const getAvailableNFTS = useCallback(
    async (pageNumber = 1) => {
      if (pageNumber === 1) setactiveIndex(0);
      if (contract.connected && window.contract) {
        const daa = await sxtService.retriveNFTs(
          pageNumber,
          nftsList.pageSize,
          true
        );
        let cards = [];
        cards = daa?.nfts || [];
        dispatch(
          setAvailableNFTs({
            nfts: cards,
            count: daa?.count || 0,
            currentPage: pageNumber,
          })
        );
        setLoading(false);
        setNftFetched(true);
      } else {
        setLoading(false);
      }
      // eslint-disable-next-line
    },
    [contract.connected, dispatch, nftsList.pageSize]
  );

  useEffect(() => {
    getAvailableNFTS(1);
    if (window.erc20contract && !connection.disconnected) {
      getAvailableNFTS(1);
    }
    // eslint-disable-next-line
  }, [contract.connected, window.erc20contract, connection.disconnected]);

  const FallbackLoading = () => {
    return (
      <div className="w-100 d-flex flex-column align-items-center">
        <CircularProgress color="secondary" />
        <h6 className="mt-3 text-secondary">Loading NFTs</h6>
      </div>
    );
  };

  const next = async () => {
    await sliderRef.current.slickNext();
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    if (nftsList.hasNext && nextIndex === items.length - 2) {
      getAvailableNFTS(nftsList.currentPage + 1);
    }
    setactiveIndex(nextIndex);
  };

  const previous = async () => {
    await sliderRef.current.slickPrev();
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setactiveIndex(nextIndex);
  };
  const swipe = () => {
    next();
  };

  const onMintSuccess = () => {
    setLoading(true);
    setNftFetched(false);
    getAvailableNFTS(1);
  };

  const slides = items.map((item, index) => (
    <NFTCard
      key={`nft-card-avail-${index}-5`}
      keyData={`nft-card-avail-${index}-5`}
      tokenInfo={item}
      init={onMintSuccess}
    />
  ));

  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    prevArrow: <PreviousArrow type="prev" gotoPrev={previous} />,
    nextArrow: <NextArrow type="next" gotoNext={next} />,
    onSwipe: swipe,
  };

  return (
    <div className="mt-3">
      {!nftFetched && <FallbackLoading />}
      {!loading && nftFetched && items.length > 0 && (
        <Slider ref={sliderRef} {...sliderSettings}>
          {slides}
        </Slider>
      )}
      {!loading && nftFetched && items.length === 0 && <ComingSoon />}
    </div>
  );
}

const PreviousArrow = (props) => {
  let { currentSlide, gotoPrev } = props;
  return (
    <>
      {currentSlide === 0 ? (
        <></>
      ) : (
        <button className="slick-prev" onClick={gotoPrev}>
          Previous
        </button>
      )}
    </>
  );
};

const NextArrow = (props) => {
  const { currentSlide, slideCount, gotoNext } = props;
  return (
    <>
      {currentSlide === slideCount - 1 ? (
        <></>
      ) : (
        <button type="button" className="slick-next" onClick={gotoNext}>
          Next
        </button>
      )}
    </>
  );
};

export default React.memo(CardSliderNew);

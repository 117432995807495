import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Container } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import wallet from "../../../assets/images/wallet.svg";
import walletR from "../../../assets/images/wallet-red.svg";
import "../../Pages/NFTDetail/nft-detail.scss";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { connect, disconnect } from "../../../redux/states/connectionReducer";
import { shortText } from "../../../helpers/globalHelper";
import { Snackbar, Alert } from "@mui/material";
import sxtService from "../../../helpers/service";
// import Web3 from 'web3';

export default function SubHeader() {
  const location = useLocation();
  const dispatch = useDispatch();
  const connection = useSelector((state) => state.connection);

  const [showBreadcrumb, setShowBreadcrumb] = useState(false);
  const [isDisconnected, setIsDisconnected] = useState(connection.disconnected);
  const [hasError, setHasError] = useState({
    show: false,
    message: "",
    variant: "error",
  });

  useEffect(() => {
    connectInstant();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (connection.disconnected) {
      setIsDisconnected(true);
    }
  }, [connection.disconnected]);

  useEffect(() => {
    updateComponentByLocation(location.pathname || "/");
    // eslint-disable-next-line
  }, [location.pathname]);

  function updateComponentByLocation(path) {
    if (path) {
      if (path === "/") {
        setShowBreadcrumb(false);
      } else if (path.includes("/nft-detail/")) {
        setShowBreadcrumb(true);
      }else if (path.includes("/details")) {
        setShowBreadcrumb(true);
      }
    }
  }

  async function connectionHandler() {
    if (connection.disconnected === false) {
      dispatch(disconnect());
      setIsDisconnected(true);
      localStorage.clear();
      setHasError({
        show: true,
        message: "Disconnected successfully.",
        variant: "success",
      });
      return;
    }
    if (typeof window.ethereum !== "undefined") {
      window.ethereum
        ?.request({
          method: "eth_requestAccounts",
        })
        .then(async (res) => {
          const provider = sxtService._getProvider();
          const onValidNetwork = await sxtService._checkConnectedNetwork(
            provider
          );
          if (!onValidNetwork) return;
          if (res) {
            localStorage.setItem("account", res[0]);
            setIsDisconnected(false);
            dispatch(connect({ address: res[0] }));
            setHasError({
              show: true,
              message: "Connected successfully.",
              variant: "success",
            });
          }
        })
        .catch((err) => {
          setHasError({ show: true, message: err.message, variant: "error" });
        });
    } else {
      setIsDisconnected(true);
      // alert("install metamask extension!!");
      window.showSnackbar("Install metamask extension!!", "info");
    }
  }

  async function connectInstant() {
    if (
      localStorage.getItem("account") !== null &&
      localStorage.getItem("account") !== undefined
    )
      window.ethereum
        ?.request({ method: "eth_requestAccounts" })
        .then((res) => {
          setIsDisconnected(false);
          dispatch(connect({ address: res[0] }));
        });
  }

  return (
    <Container>
      <div className="d-flex space-between-flex mb-3">
        <Breadcrumb>
          {showBreadcrumb && (
            <>
              <BreadcrumbItem>
                <Link to="/">NFT</Link>
              </BreadcrumbItem>
              <BreadcrumbItem active>Details</BreadcrumbItem>
            </>
          )}
        </Breadcrumb>
        {/* <div className="toggle-container">
          <input
            type="checkbox"
            checked={isDisconnected}
            onChange={() => connectionHandler()}
          />
          <div className="switch-btn">
            <div className="inner-toggle-switch">
              <div className="Connected ">
                <div className="connection-btn-text">
                  <img src={wallet} alt="" />
                  <div>
                    <h5>Connected</h5>
                    <span>({shortText(connection.accountAddress)})</span>
                  </div>
                </div>
              </div>
              <div className="Diconnected">
                <div className="connection-btn-text">
                  <img src={walletR} alt="" />
                  <div>
                    <h5>Disconnected </h5>
                    <span>Connect your Wallet</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
      <Snackbar
        open={hasError.show}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        autoHideDuration={4000}
        onClose={() => setHasError({ show: false, message: "" })}
      >
        <Alert
          onClose={() => setHasError({ show: false, message: "" })}
          severity={hasError.variant}
          sx={{ width: "100%" }}
        >
          {hasError.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}

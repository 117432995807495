import React from "react";
import Header from "../components/Common/Header";
import SubHeader from "../components/Common/SubHeader";
const Layout = (props) => {
  return (
    <>
      <div className="main-app">
        <Header />
        {props?.subheader && <SubHeader />}
        <div className="main-container">{props.children}</div>
      </div>
    </>
  );
};
export default Layout;

import { createSlice } from "@reduxjs/toolkit";
export const nftsSlice = createSlice({
  name: "nfts",
  initialState: {
    nftFetchRequired: false,
    lastfetched: 0,
    nfts: null,
    soldNfts: null,
  },
  reducers: {
    setNfts: (state, data) => {
      try {
        state.nftFetchRequired = false;
        state.lastfetched = Date.now();
        state.nfts = data.payload.nfts;
      } catch (err) {
        console.log(err);
      }
    },
    setSoldNfts: (state, data) => {
      try {
        state.nftFetchRequired = false;
        state.lastfetched = Date.now();
        state.soldNfts = data.payload.soldNfts;
      } catch (err) {
        console.log(err);
      }
    },
    nftFetch: (state) => {
      state.nftFetchRequired = true;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setNfts, setSoldNfts, nftFetch } = nftsSlice.actions;

export default nftsSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";
export const connectionSlice = createSlice({
  name: "connection",
  initialState: {
    disconnected: true,
    accountAddress: "-",
  },
  reducers: {
    connect: (state, data) => {
      state.disconnected = false;
      state.accountAddress = data.payload.address;
    },
    disconnect: (state) => {
      state.disconnected = true;
      state.accountAddress = "-";
    },
  },
});

// Action creators are generated for each case reducer function
export const { connect, disconnect } = connectionSlice.actions;

export default connectionSlice.reducer;

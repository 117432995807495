import React, { useCallback, useEffect, useState } from "react";
import { Card, CardBody, CardTitle, CardSubtitle, Button } from "reactstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import sxtService from "../../../helpers/service";
import MintNow from "../MintNowButton";
import { setNFTFetchRequired } from "../../../redux/states/nftsReducerNew";

function NFTCard({ tokenInfo, init = () => {}, ...rest }) {
  const connection = useSelector((state) => state.connection);
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isPrevMinted, setisPrevMinted] = useState(false);
  const [image, setImage] = useState(null);
  const [remains, setRemains] = useState(0);
  const [total, setTotal] = useState(0);
  const [erc20ConvertedPrice, seterc20ConvertedPrice] = useState(0);
  const [isOldNFT, setIsOldNFT] = useState(false);

  function isOnOldContract() {
    if (tokenInfo?.isOld) {
      setIsOldNFT(true);
    }
  }

  useEffect(() => {
    isOnOldContract();
    isPreviouslyMinted();
    if (localStorage.getItem("thash")) {
      window.showProgress("Mint processing, Please don't refresh the page.");
      let progressInterval = setInterval(() => {
        getTransactionReceipt(localStorage.getItem("thash")).then((res) => {
          if (res) {
            localStorage.removeItem("thash");
            window.hideProgress();
            if (res.status === 1) {
              window.showSuccessModal();
              clearInterval(progressInterval);
              dispatch(setNFTFetchRequired());
            } else {
              window.showSnackbar("Mint process failed.");
              clearInterval(progressInterval);
            }
          }
        });
      }, 4000);
    }
    // eslint-disable-next-line
  }, []);

  const getERC20Price = useCallback(async () => {
    if (tokenInfo) {
      const erc20Price = await sxtService.convertToErc20(tokenInfo?.erc20Price);
      seterc20ConvertedPrice(erc20Price);
    }
  }, [tokenInfo]);

  useEffect(() => {
    isPreviouslyMinted();
    // eslint-disable-next-line
  }, [connection.disconnected]);

  useEffect(() => {
    if (tokenInfo) {
      setImage(tokenInfo.imageURL);
      setRemains(tokenInfo.maxTokenSupply - tokenInfo.currentTokenSupply);
      setTotal(tokenInfo.maxTokenSupply);
      getERC20Price();
    }
    // eslint-disable-next-line
  }, [tokenInfo, getERC20Price]);

  async function getTransactionReceipt(hash) {
    return window.provider.getTransactionReceipt(hash);
  }

  function viewNFTHandler() {
    // let path = "nft-detail";
    // if(isOldNFT) path = "oldnft-detail"
    // navigate(`/${path}/${tokenInfo.id}`, {
    //   state: { ...tokenInfo, isPrevMinted },
    // });
  }

  async function isPreviouslyMinted() {
    // if (window.contract && !connection.disconnected) {
    //   const result = await window.contract.isPreviouslyMintedFrom(
    //     tokenInfo.id,
    //     connection.accountAddress
    //   );
    //   setisPrevMinted(result);
    // } else {
    //   setisPrevMinted(false);
    // }
    if(!isOldNFT){
      const result = await sxtService.balanceOf(window.contract, connection.accountAddress, tokenInfo.id).then(res => res);
      setisPrevMinted(result);
    }else{
      const result = await sxtService.isPreviouslyMinted(tokenInfo?.contractAddress || null, connection.accountAddress, tokenInfo.id).then(res => res);
    }
  }

  async function mintClickHandler(type) {
    let isReadyToMint = await sxtService.isEnvironmentReadyToMint();
    if (tokenInfo && isReadyToMint)
      sxtService.mintNFT(
        type,
        tokenInfo.id,
        connection.accountAddress,
        tokenInfo.ethPrice,
        tokenInfo.erc20Price,
        init
      );
  }

  const MintButtons = () => {
    if (!tokenInfo) return <></>;
    if (isPrevMinted) {
      return (
        <Link
          to={{
            pathname: `/${isOldNFT == true ? "old" : ""}nft-detail/${
              tokenInfo.id
            }`,
          }}
          state={{
            ...tokenInfo,
            isPrevMinted,
            contractAddress: tokenInfo?.contractAddress,
          }}
        >
          <MintNow text="View NFT" clickHandler={() => {}} />
        </Link>
      );
    } else {
      return (
        <Link
          to={{
            pathname: `/${isOldNFT == true ? "old" : ""}nft-detail/${
              tokenInfo.id
            }`,
          }}
          state={{
            ...tokenInfo,
            isPrevMinted,
            contractAddress: tokenInfo?.contractAddress,
          }}
        >
          <MintNow text="View NFT" clickHandler={() => {}} />
        </Link>
      );
    }
    // const { ethPrice, erc20Price, hasPrice, symbol } = tokenInfo;
    // if (!hasPrice) return <></>;
    // //return <MintNow clickHandler={() => mintClickHandler("free")} />;

    // if (ethPrice > 0 && erc20Price > 0) {
    //   return <></>;
    //   // return (
    //   //   <div className="dual-mint-button">
    //   //     <MintNow
    //   //       text={`${sxtService.convertToEth(ethPrice)} ETH`}
    //   //       clickHandler={() => mintClickHandler("eth")}
    //   //     />
    //   //     <MintNow
    //   //       text={`${erc20ConvertedPrice} ${symbol}`}
    //   //       clickHandler={() => mintClickHandler("erc20")}
    //   //     />
    //   //   </div>
    //   // );
    // } else if (ethPrice > 0 && erc20Price === 0) {
    //   return (
    //     // <MintNow
    //     //   text={`${sxtService.convertToEth(ethPrice)} ETH`}
    //     //   clickHandler={() => mintClickHandler("eth")}
    //     // />
    //     <></>
    //   );
    // } else if (ethPrice === 0 && erc20Price > 0) {
    //   return (
    //     // <MintNow
    //     //   text={`${erc20ConvertedPrice} ${symbol}`}
    //     //   clickHandler={() => mintClickHandler("erc20")}
    //     // />
    //     <></>
    //   );
    // }
  };
 const getRouteName = () => {
  const  name = tokenInfo.name;
  return name && name.toLowerCase().replaceAll(' ','-')
 }
  return (
    <>
      <Card key={rest.keyData || `nft-card-sold-${tokenInfo.id}`}>
        <Link
          to={{
            pathname: `/${isOldNFT ?  `${getRouteName()}/details` : `nft-detail/${tokenInfo.id}` }`,
          }}
          state={{
            ...tokenInfo,
            isPrevMinted,
            contractAddress: tokenInfo?.contractAddress,
          }}
        >
          <div className="cardImg-wrapper">
            <img src={image} alt="" />
          </div>
        </Link>
        <CardBody>
          <CardTitle tag="h5">{tokenInfo.name || "SpaceX"}</CardTitle>
          <CardSubtitle tag="h6">
            <span>
              {remains === 0 ? (
                <></>
              ) : (
                <>
                  {remains || 0} of {total || 0} Left
                </>
              )}
            </span>
          </CardSubtitle>
          {rest.disableMint ? (
            <>
              <Button className="btn btn-disabled">Sold Out</Button>
            </>
          ) : (
            <MintButtons />
          )}
        </CardBody>
      </Card>
    </>
  );
}

export default NFTCard;

import NEW from "../../../assets/images/new.svg";
import spaceandtimeLogo from "../../../assets/images/logo.png";
import "./header.scss";
import React from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

export default class Example extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false,
    };
  }
  toggle() {
    const elem = document.querySelector("#toggle01-btn");
    if (this.state.isOpen) {
      elem.classList.remove("toggleShow");
    } else {
      elem.classList.add("toggleShow");
    }

    this.setState({
      isOpen: !this.state.isOpen,
    });
  }
  render() {
    return (
      <header>
        <div className="container">
          <Navbar color="dark" dark expand="md">
            <NavbarBrand target="_blank" href="https://www.spaceandtime.io/">
              <img src={spaceandtimeLogo} alt="" />
            </NavbarBrand>
            <NavbarToggler id="toggle01-btn" onClick={this.toggle}>
              <div className="header-menu-button icon-top"></div>
              <div className="header-menu-button icon-medium"></div>
              <div className="header-menu-button icon-bottom"></div>
            </NavbarToggler>
            <Collapse isOpen={this.state.isOpen} navbar>
              <Nav className="ml-auto" navbar>
                <NavItem>
                  <NavLink href="https://www.spaceandtime.io/home">
                    vision
                  </NavLink>
                </NavItem>
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav caret className="mobile-nav">
                    About
                  </DropdownToggle>
                  <a
                    href="https://www.spaceandtime.io/home#about"
                    aria-haspopup="true"
                    className="dropdown-toggle nav-link desktop-nav"
                    aria-expanded="false"
                  >
                    About
                  </a>
                  <DropdownMenu end>
                    <DropdownItem>
                      <NavLink href="https://www.spaceandtime.io/home#about-backers">
                        Backers
                      </NavLink>
                    </DropdownItem>
                    <DropdownItem>
                      <NavLink href="https://www.spaceandtime.io/home#about-partners">
                        Partners
                      </NavLink>
                    </DropdownItem>
                    <DropdownItem>
                      <NavLink href="https://www.spaceandtime.io/home#about-team">
                        Team
                      </NavLink>
                    </DropdownItem>
                    <DropdownItem>
                      <NavLink href="https://www.spaceandtime.io/home#analytics-roadmap">
                        Roadmap
                      </NavLink>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
                <NavItem>
                  <NavLink href="https://www.spaceandtime.io/open-jobs">
                    Careers
                  </NavLink>
                </NavItem>
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav caret className="mobile-nav">
                    Resources
                  </DropdownToggle>
                  <a
                    aria-haspopup="true"
                    href="https://www.spaceandtime.io/resources"
                    className="dropdown-toggle nav-link desktop-nav"
                    aria-expanded="false"
                  >
                    Resources
                  </a>
                  <DropdownMenu end>
                    <DropdownItem>
                      <NavLink href="https://docs.spaceandtime.io/">
                        Docs
                      </NavLink>
                    </DropdownItem>
                    <DropdownItem>
                      <NavLink href="https://www.spaceandtime.io/blog">
                        Blog
                      </NavLink>
                    </DropdownItem>
                    <DropdownItem>
                      <NavLink href="https://www.spaceandtime.io/news">
                        News
                      </NavLink>
                    </DropdownItem>
                    <DropdownItem>
                      <NavLink href="https://www.spaceandtime.io/press-kit">
                        PR Kit
                      </NavLink>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
                <NavItem>
                  <NavLink href="/">
                    NFT
                    <img src={NEW} alt="" />
                  </NavLink>
                </NavItem>
              </Nav>
            </Collapse>
          </Navbar>
        </div>
      </header>
    );
  }
}
